<template>
        
    <div class="row">
        <!-- Left Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Select Patient: </span>                 
                </div>

                <div class="col-md-7">
                    <AsyncSelect
                        placeholder="Patient Name, ID, Mobile No"
                        v-model="patient"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalQuery"
                        :format-label="formatPatientLabel"
                    />
                </div>
            </div>

            <div class="row h-40 mt-1">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient ID: </span>
                </div>

                <div class="col-md-7">
                    <span> <strong>{{ patientId }}</strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient Phone No: </span>      
                </div>

                <div class="col-md-7">
                    <span> <strong> {{patientMobile && patientMobile.value}} </strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Select Admission info: </span>      
                </div>

                <div class="col-md-7">
                    <div>
                        <v-select
                            placeholder="Select Admission Info"
                            label="text"
                            :options="registrationList"
                            v-model="formData.ipd_register_id"
                            :reduce="text => text.id"
                        />
                    </div>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Ward / Bed / Cabin: </span>      
                </div>

                <div class="col-md-7">
                    <span> <strong>
                        {{ selectedRegisterInfo && selectedRegisterInfo.service_resource.name }}
                    </strong> </span>
                </div>
            </div>

        </div>

        <div class="col-md-2">
            <button
                  type="button"
                  class="btn btn-primary"
                  @click="openPatientAddModal"
              >
                New Patient
              </button>
        </div>

        <!-- Right Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">

            <div class="row h-40 mb-5">

                <div class="col-md-7">
                    <input
                        v-model="patientSerialNo"
                        name="serial"
                        type="number"
                        class="form-control"
                        placeholder="Patient Id"
                    />
                </div>

                <div class="col-md-5">
                    <button 
                        class="btn btn-primary"
                        @click="searchPatientById"
                    >
                        Go
                    </button>
                </div>

            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Invoice Date: </span>      
                </div>

                <div class="col-md-7">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
            </div>

        </div>

        <div class="row h-40">
            <div class="col-md-2 d-flex align-items-center">
                <span> Consultant / Doctor: </span>      
            </div>

            <div class="col-md-6 px-2">
                <AsyncSelect
                    placeholder="Select consultant"
                    v-model="formData.service_resource_id"
                    :api-service="fetchServiceList"
                    :additional-query="{ resource_type: 'human_resources' }"
                    :format-label="formatPatientLabel"
                    :reduce="consultant => consultant.id"
                />
            </div>
        </div>

        <div class="mt-2 col-md-5">
            <AsyncSelect
                placeholder="Select Product"
                v-model="product_id"
                :api-service="fetchProductList"
                :reduce="name => name.id"
                :format-label="option => option.text"
                :additional-query="additionalProductQuery"
                label="text"
            />
        </div>

        <AddPatientModal
            v-if="$store.state.isModalOpenTwo"
            :doctors="doctors"
            @onCreateProfile="onCreateProfile"
        />

        <Loader v-if="isLoader" />

    </div>

</template>

<script setup>
import { ref, onMounted, watch, inject, computed, reactive } from 'vue'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleContact from '@/services/modules/contact'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleInventory from "@/services/modules/inventory";
import handleHospital from '@/services/modules/hospital'
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import useDate from "@/services/utils/day";
import Loader from "@/components/atom/LoaderComponent";

const props = defineProps({
  addNewItem: {
    type: Function
  },
  products: {
    type: Array,
    default: []
  },
  tableItems: {
    type: Array
  }
})

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const date = useDate();
const patient = ref(null);
const userInfo = ref(null);
const isLoader = ref(false);
const patientSerialNo = ref(null);
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const selectedData = ref({});
const formData = inject('formData');
const showError = inject('showError')
let loader =ref(false);
const product_id = ref(null);
const registrationList = ref([]);
const additionalQuery = {
    type: "patient"
}
const additionalProductQuery = {
    product_type: "services",
    from: "billing"
}
const doctors = reactive([]);

const { fetchServiceList, fetchPatientDetailsBySerial } = handleHospital()
const {fetchProductList} = handlePurchase()
const {getProduct} = handleInventory()
const {fetchContactProfiles, fetchSingleContactProfile} = handleContact()
const { fetchIpdRegisterList } = handleHospitalBilling()
const { formatPatientLabel } = useAsyncDropdownHelper()

//computed
const selectedRegisterInfo = computed(() => {
    if (formData.value.ipd_register_id) {
        return registrationList.value.find(registration => registration.id === formData.value.ipd_register_id)
    }
    return null
})

const patientMobile = computed(() => {
    if (selectedData.value.profile_items && selectedData.value.profile_items.length) {
        return selectedData.value.profile_items.find(item => item.field === 'mobile_no')
    }
    return null
})

const patientId = computed(() => {
    if (
        selectedData.value.company_roles &&
        selectedData.value.company_roles.length &&
        selectedData.value.company_roles[0].pivot
    ) {
        return selectedData.value.company_roles[0].pivot.serial_no
    }
    return null
})

onMounted(() => {
    formData.value.date = date.currentDate();
})

watch(patient, () => {
    
    patientSerialNo.value = null;
    registrationList.value = [];
    const patientQuery = companyQuery + '&type=patient';
    formData.value.contact_profile_id = patient.value.id;
    const ipdQuery = companyQuery + `&contact_profile_id=${formData.value.contact_profile_id}`;

    fetchSingleContactProfile(patient.value.id, patientQuery).then(res => {
        if (res.data) {
            selectedData.value = formData.value.contact = res.data;
        }
    })

    fetchIpdRegisterList(ipdQuery).then(res => {
        if(res.data) registrationList.value = res.data
    })
})

watch(product_id, async () => {
    const productResp = await getProduct({
        id: product_id.value,
        company_id: companyId
    })
    if (!productResp.data) {
        showError("Something went wrong please try again");
        return;
    }
    let selected = productResp.data;
    let product = {
        name: selected.name
    }
    props.tableItems.push({
        id: selected.id,
        product_id: product_id.value,
        name: selected.name,
        description: selected.description.unit.description,
        quantity: 1,
        rate: selected.description.sales_price,
        discount: 0,
        vat: selected.description.vat_rate,
        sub_total: 0,
        discount_amount: 0,
        discount_percent: 0,
        vat_amount: 0,
        total: 0,
        isEdit: false,
        product: product
    })
})

const openPatientAddModal = () => {
    const query = `?company_id=${companyId}&type=doctor`
    fetchContactProfiles(query).then((res) => {
        if(res.status) doctors.push(...res.data)
    }).then(() => {
        $store.state.isModalOpenTwo = true
    })
}

const searchPatientById = async () => {

    if(!patientSerialNo.value) return;
    userInfo.value = null
    isLoader.value = true;
    const query = `?company_id=${companyId}`;

    await fetchPatientDetailsBySerial(query, patientSerialNo.value)
        .then((res) => {
            
            if (!res.status) {
                return showError(res.message)
            }

            formData.value.ipd_register_id = null
            
            userInfo.value = res.data
            patient.value = {
                id: userInfo.value.id,
                name: userInfo.value.full_name
            }
        })
    
    isLoader.value = false;
}

const onCreateProfile = (profileInfo) => {
    patient.value = profileInfo;
    patient.value.name = profileInfo.full_name
}

</script>

<style>
.select label{
    font-size: 14px;
}
.h-40{
    height: 40px;
    align-items: center;
}
.vs__selected{
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}
</style>